<template>
  <section v-if="faqList.length > 0" class="faqs">
    <BaseTitleSection :title="title" class="faqs__title" />
    <BlockExpansionPanelList :items="faqList" is-html />
    <div v-if="showMore" class="faqs__show-more">
      <BaseButton
        :label="$t('common.actions.show_more')"
        flat
        size="sm-lg"
        light
        dense
        icon-left="arrow_drop_down"
        @click="changeList"
      />
    </div>
    <div v-else class="faqs__show-more">
      <BaseButton
        :label="$t('common.actions.show_less')"
        flat
        size="sm-lg"
        light
        icon-left="arrow_drop_up"
        @click="changeList"
      />
    </div>
  </section>
</template>

<script>
import BlockExpansionPanelList from '@design-system/block/expansion-panel-list/expansion-panel-list.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'SectionFAQs',
  components: { BlockExpansionPanelList },
  props: {
    staticFaqs: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      title: 'Frequently asked questions',
      step: 4,
      interval: 0,
      showMore: false,
      faqList: []
    }
  },
  computed: {
    ...mapGetters('global', ['getFaqs'])
  },
  mounted () {
    this.changeList()
  },
  methods: {
    changeList () {
      const faqList = this.getFaqs.length > 0 ? this.getFaqs : this.staticFaqs
      this.showMore = !this.showMore
      if (this.showMore) {
        this.faqList = faqList.slice(0, this.step)
        return
      }
      this.faqList = faqList
    }
  }
}
</script>

<style lang="scss" scoped>
.faqs {
  &__title{
    @apply text-center mb-6 lg:mb-8;
  }
  &__show-more {
  @apply mt-6 lg:mt-8;
  }
}
</style>
